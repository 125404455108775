/* Below animations are fore modal created using React-Modal */

.ReactModal__Overlay {
    transition: transform 300ms ease-in-out;
    transition-delay: 100ms;
    transform: scale(0);
    /* transform: translateY(-150%); */
}

.ReactModal__Overlay--after-open {
    transform: scale(1);
    /* transform: translateY(0%); */
}

.ReactModal__Overlay--before-close {
    transform: scale(0);
    /* transform: translateY(-150%); */
}

.speaker-border {
    border: 5px solid #064b60 !important;
    cursor: pointer;
}

.speaker-border:hover {
    border: 2px solid #064b60 !important;
    cursor: pointer;
}

.chair-name ::before {
    content: attr(data-icon);
    font-size: 1.25em;
}

.typewriter span {
    overflow: hidden;
    /* Ensures the content is not revealed until the animation */
    border-right: .15em solid orange;
    /* The typwriter cursor */
    white-space: nowrap;
    /* Keeps the content on a single line */
    margin: 0 auto;
    /* Gives that scrolling effect as the typing happens */
    letter-spacing: .05em;
    /* Adjust as needed */
    animation: typing 3.5s steps(40, end), blink-caret .75s step-end infinite;
}

/* The typing effect */

@keyframes typing {
    from {
        width: 0
    }
    to {
        width: 100%
    }
}

/* The typewriter cursor effect */

@keyframes blink-caret {
    from, to {
        border-color: transparent
    }
    50% {
        border-color: orange;
    }
}

.dropdown:hover .dropdown-menu {
    display: block;
  }